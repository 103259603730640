<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.meta.inProccessTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.inProccessTab"
      >
        In Proccess
        <span class="ml-2" v-if="S_COUNTER_SENDING_TO_CREDITORS">
          <feather-icon
            icon
            :badge="
              S_COUNTER_SENDING_TO_CREDITORS <= 99
                ? S_COUNTER_SENDING_TO_CREDITORS
                : '99+'
            "
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.sentTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.sentTab"
      >
        Sent
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.notSentTab }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.notSentTab"
      >
        Not Sent
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SendingCreditors",
  computed: {
    ...mapState({
      S_COUNTER_SENDING_TO_CREDITORS: (state) =>
        state.SendingToCreditorsStore.S_COUNTER_SENDING_TO_CREDITORS,
    }),
  },
};
</script>

<style scoped></style>
